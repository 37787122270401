const addLogoAnimation = () => {
	if (window.matchMedia("(min-width: 640px)").matches) {
		const logo = document.querySelector(".logo h1")!
		logo.classList.add("animated")

		const keyframes = [
			{
				fontSize: "9vw",
				width: "7.45em",
				transform: "translateY(40vh)",
			},
			{
				fontSize: "5vw",
				width: "100%",
				transform: "translateY(0)",
			},
		]

		const animation = logo.animate(keyframes, {
			duration: 1000,
			fill: "both",
		})

		animation.pause()

		const updateAnimation = () => {
			const keyframeSteps = 1000 / window.innerHeight
			const pagePosition = document.documentElement.scrollTop
			animation.currentTime = pagePosition * keyframeSteps * 2
		}

		window.addEventListener("scroll", updateAnimation)
		window.addEventListener("resize", updateAnimation)
	}
}

export default addLogoAnimation
