const addImageAnimations = () => {
	const images = document.querySelectorAll(".animate")

	const observerOptions = {
		threshold: 0.25,
	}

	const observerCallback = (entries: IntersectionObserverEntry[]) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) entry.target.classList.add("in-view")
		})
	}

	const observer = new IntersectionObserver(observerCallback, observerOptions)
	images.forEach(image => observer.observe(image))
}

export default addImageAnimations
