const hydrateForm = () => {
	const form = document.querySelector("#contact-form") as HTMLFormElement
	const fields = document.querySelectorAll(
		"#contact-form input, #contact-form textarea"
	) as NodeListOf<HTMLInputElement | HTMLTextAreaElement>
	const submit = document.querySelector("#submit") as HTMLButtonElement

	const errorMessage = document.querySelector("#error") as HTMLDivElement
	const successMessage = document.querySelector("#success") as HTMLDivElement

	const validityCheck = () => {
		submit.disabled = !form.checkValidity()
	}

	const initializeForm = () => {
		form.addEventListener("input", validityCheck)
		// Add eventListeners on all inputs that mark it changed
		fields.forEach(field => {
			// Remove any 'changed' class from a previous submission
			field.classList.remove("field-changed")
			// Add the 'changed' class when field is changed
			field.addEventListener(
				"input",
				e => (e.target as HTMLElement).classList.add("field-changed"),
				{ once: true }
			)
		})
		// Disable submit
		validityCheck()
	}

	form.addEventListener("submit", e => {
		e.preventDefault()
		sendData()
	})

	// Initial form validity check
	initializeForm()

	const sendingStyles = (status: boolean) => {
		if (status) {
			submit.disabled = true
			document.documentElement.classList.add("sending")
		} else {
			submit.disabled = false
			document.documentElement.classList.remove("sending")
		}
	}

	const handleResponse = (ok: boolean) => {
		sendingStyles(false)
		if (ok) {
			successMessage.style.display = "block"
		} else {
			errorMessage.style.display = "block"
		}
	}

	const sendData = async () => {
		if (!form.checkValidity()) {
			alert("Niet alle velden zijn ingevuld")
			return
		}
		sendingStyles(true)

		const response = await fetch(form.action, {
			method: form.method,
			// @ts-ignore: https://github.com/microsoft/TypeScript/issues/30584
			body: new URLSearchParams(new FormData(form)),
		})
		if (!response.ok) {
			handleResponse(false)
			return
		}
		handleResponse(true)
		// Reset and reinitialize form
		form.reset()
		initializeForm()
	}
}

export default hydrateForm
